<template>
  <navigation-layout
    :category="category"
    :entries="navigationEntries"
    :footer-entries="navigationFooter">
    <layout-container
      :category="category"
      :showInFullscreen="showInFullscreen">
      <content-container
        :category="category"
        :adjustContentHeight="true"
        @updateFullscreen="showInFullscreen = $event">
      </content-container>
    </layout-container>
  </navigation-layout>
</template>

<script>
  import DesignMixin from '../mixin/design'

  export default {
    data() {
      return {
        ws: null,
        loading: false,
        category: null,
        navigationEntries: [],
        navigationFooter: [],
        showInFullscreen: false,
        sendClosingMessage: false,
      }
    },
    beforeUnmount() {
      this.sendClosingMessage = true
    },
    methods: {
      /**
       * Retrieve the parent category for icon and background
       * and colors. Set the icon for navigation bar
       * @return {Promise<NavigationFailure | void | undefined>}
       */
      async resolveParentCategory(categorySlug) {
        let categoryId = this.$helper.extractIdFromSlug(categorySlug)
        if (categoryId) {
          // Apply the current design based on the MAM
          // category (icon, images and colors)
          this.category = await this.applyDesign(categoryId)

          if (!this.category.error) {
            // Creates the top bar navigation
            await this.buildNavigation()
            this.navigationFooter = await this.$helper.buildFooter(this.$api, this.category)

            this.saveUrl = null
            this.$store.commit('auth/setSaveUrl', null)

            // Update the UI Params
            this.$emit('updateUI')
          } else {
            const isAuthenticated = this.$store.getters['auth/isAuthenticated']
                && this.$store.getters['auth/username'] !== 'guest'
            if (!isAuthenticated) {
              if (this.$route.fullPath.indexOf('auth/login') === -1) {
                this.$store.commit('auth/setSaveUrl', this.$route.fullPath)
              }
              this.go(`/${this.$route.params.slug}/auth/login`)
            }
            else {
              this.go(`/notfound`)
            }
          }
        }
      },
      /**
       * Retrieve child categories for create the navigation
       * It is everytime created of the first level of the
       * backend MAM structure
       */
      async buildNavigation() {
        if (!this.navigationEntries || this.navigationEntries.length === 0) {
          this.navigationEntries = await this.$api.findCategories(this.$helper.extractIdFromSlug(this.$route.params.slug))
          this.navigationEntries = this.navigationEntries.map(cat => {
            return {
              category: cat,
              label: cat.categoryname,
              link: '/' + this.$route.params.slug + '/' + this.$helper.slugify(cat.categoryname) + '-' + cat.id,
            }
          })
          //add Download to menu if available under project folder
          const pjId = this.$store.getters['auth/user']?.startId
          if (pjId) {
            const children = await this.$api.findCategories(pjId)
            for (let i = 0; i < children.length; i++) {
              if (children[i].categoryname === "Downloads") {
                let myo = {category: children[i], label: 'Downloads', link: '/' + this.$route.params.slug + '/' + this.$helper.slugify(children[i].categoryname) + '-' + children[i].id}
                this.navigationEntries.push(myo)
              }
            }
          }
        }

        if (!this.$route.path.includes('/auth/register')
          && !this.$route.path.includes('/auth/login')
          && !this.$route.path.includes('/auth/logout')
          && !this.$route.path.includes('/auth/password')
          && !this.$route.path.includes('/auth/forgot')) {
          this.callDefaultRoute()
        }
      },
      /**
       * Changes the meta title by changing route
       */
      changeMetaTitle() {
        let element = document.querySelector('head title')
        if (element
          && this.category
          && this.category.categoryname) {
          element.innerHTML = this.category.categoryname
        }
      },
      callDefaultRoute() {
        // Redirect to the first element if the page has not been set
        // Default value is the first element in MAM
        if (this.navigationEntries
          && this.navigationEntries.length > 0
          && !this.$route.params.pathMatch) {
          return this.go(this.navigationEntries[0].link)
        }
      },
      async connectToWebSocket() {
        if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
          return new Promise(resolve => {
            this.ws = new WebSocket(this.$store.getters['config/frontendWSURI'])
            this.ws.onopen = () => {
              resolve(this.ws)
            }
            this.ws.onerror = (err) => {
              console.error(err)
            }
            this.ws.onmessage = (message) => {
              message = JSON.parse(message.data)
              this.$store.commit('category/setLocationUsers', null)
              switch (message.cmd) {
                case 'setCurrentLocation':
                  this.$store.commit('category/setLocationUsers', message.values)
                  break
                case 'getCurrentLocation':
                  this.$store.commit('category/setLocationUsers', message.values)
                  break
              }
            }
          })
        }
        return Promise.resolve(this.ws)
      },
      async sendCurrentLocation() {
        let websocketClient = await this.connectToWebSocket()
        if (websocketClient) {
          let message = {
            cmd: 'setCurrentLocation',
            values: {
              user: this.$store.getters['auth/user']?.login,
              path: this.sendClosingMessage ? null : this.category.path,
            }
          }
          websocketClient.send(JSON.stringify(message))
        }
      },
    },
    /**
     * Retrieves the category based on the slug and
     * uses the colors to design the application in your primary,
     * secondary and tertiary color.
     * @return {Promise<void>}
     */
    watch: {
      '$route': {
        deep: true,
        immediate: true,
        async handler() {
          this.loading = true
          let categoryId = this.$route.params.slug
          if (this.$route.params.pathMatch) {
            categoryId = this.$route.params.pathMatch[this.$route.params.pathMatch.length - 1]
          }
          await this.resolveParentCategory(categoryId)
          await this.sendCurrentLocation()
          this.changeMetaTitle()
          this.loading = false
        },
      },
    },
    inject: [
      '$api',
      '$helper',
    ],
    mixins: [
      DesignMixin,
    ],
  }
</script>